
















import {Vue } from 'vue-property-decorator';
import ReflectionStatusListTemplate from '@/components/templates/ReflectionStatusListTemplate/ReflectionStatusListTemplate.vue';
import * as Config from '@/config';
export interface DataType {
  dataCommands: any;
  perPage: number;
  currPage: number;
  keyword: string;
  scorp: any;
  statusTotalCount: number;
  status: number;
  loading: boolean;
  errorMessageLogs: string;
}
export default Vue.extend({
  name: 'ReflectionStatusListPage',
  components: {
    ReflectionStatusListTemplate,
  },
  data: (): DataType => ({
    perPage: Config.PAGE_DEFAULT,
    currPage: 1,
    scorp: ['detail'],
    keyword: '',
    statusTotalCount: 0,
    dataCommands: [],
    status: -1,
    loading: false,
    errorMessageLogs: '',
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  created() {
    if (typeof localStorage.statusSortKey === 'undefined') {
      localStorage.setItem('statusSortKey', 'revision');
    }
    if (typeof localStorage.statusSortName === 'undefined') {
      localStorage.setItem('statusSortName', 'desc');
    }
    if (typeof localStorage.pageSizeStatus === 'undefined') {
      localStorage.setItem('pageSizeStatus', Config.PAGE_DEFAULT.toString());
      this.perPage = Config.PAGE_DEFAULT;
    } else {
      this.perPage = parseInt(localStorage.pageSizeStatus);
    }
  },
  mounted() {
    const dataQuery = this.$route.query;
    if (dataQuery.controllerId !== undefined) {
      localStorage.setItem('controllerId', dataQuery.controllerId.toString());
      this.getListCommands(dataQuery.controllerId);
    }
  },
  methods: {
    changePerPage(value: any): any {
      this.perPage = value;
      localStorage.setItem('pageSizeStatus', value);
      this.getListCommands(localStorage.controllerId);
    },
    changePage(value: any): any {
      this.currPage = value;
      this.getListCommands(localStorage.controllerId);
    },
    selectDetail(value: any): any {
      console.log(value);
      this.keyword = value.toString();
      this.getListCommands(localStorage.controllerId);
    },
    selectStatus(value: any): any {
      this.status = value;
      this.getListCommands(localStorage.controllerId);
      console.log('value', value);
    },
    clickBackGate(value: any): any {
      this.$router.push({ path: 'gates'});
    },
    getListCommands(ID: any): any {
      // for (let i = 1; i < 100; i++) {
      //   this.dataCommands.push({
      //   commandId: `9E348CE0-B958-41A7-9704-6A23F646A3F${i}`,
      //   controllerId: `9E348CE0-B958-41A7-9704-6A23F646A3F${i}`,
      //   revision: i,
      //   type: 0,
      //   detail: '個人コードの追加1',
      //   items: [
      //     {
      //       target: 0,
      //       detail: 'ONにしました',
      //       pCodeId: 'JE11731A2E',
      //       pCodeName: '事業開発部1',
      //       userId: 'alligater',
      //       userName: '蟻　芸太',
      //       gateId: 'zpsHmwSwxfSw',
      //     },
      //     {
      //       target: 0,
      //       detail: 'ONにしました1',
      //       pCodeId: 'JE11731A2E',
      //       pCodeName: '事業開発部1',
      //       userId: 'alligater',
      //       userName: '蟻　芸太',
      //       gateId: 'zpsHmwSwxfSw',
      //     },
      //   ],
      //   status: i,
      //   registered: 1546268400,
      //   updated: 1546268400,
      //   sendAt: 1546268400,
      //   });
      // }
      // this.statusTotalCount = 100;
      this.loading = true;
      let apiLink = Config.CONTROLLER_COMMAND_API +
      '?controllerId=' + encodeURIComponent(ID) + '&limit=' + localStorage.pageSizeStatus +
      '&page=' + this.currPage + '&keyword=' + this.keyword + '&scorp=' + this.scorp + '&sortKey=' +
      localStorage.statusSortName + ':' + localStorage.statusSortKey;
      if (this.status !== undefined && this.status !== null && this.status >= 0 ) {
        apiLink = Config.CONTROLLER_COMMAND_API +
      '?controllerId=' + encodeURIComponent(ID) + '&limit=' + localStorage.pageSizeStatus +
      '&page=' + this.currPage + '&keyword=' + this.keyword + '&scorp=' + this.scorp + '&sortKey=' +
      localStorage.statusSortName + ':' + localStorage.statusSortKey + '&status=' + this.status;
      }
      this.$http.get(apiLink)
        .then((response: any) => {
          this.resetData();
          this.loading = false;
          if (!this.isEmpty(response.body.controllerCommands)) {
            this.dataCommands = response.body.controllerCommands;
            this.statusTotalCount = response.body.maxRecords;
            this.currPage = Math.ceil(response.body.startRecord / this.perPage);
          } else {
            this.statusTotalCount = 0;
            this.currPage = 1;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.resetData();
          this.loading = false;
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        }); // catch
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.dataCommands = [];
      this.errorMessageLogs = '';
    },
  },
});
