




































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import { DataTableHeader } from 'vuetify';
import moment from 'moment';
export interface TypeItem {
  title: string;
}
export interface DataType {
  searchTargetItems: SearchTargetItem[];
  selectedItem: Type[];
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
}
interface Type {
  revision: number;
  content: string;
  status: string;
}
export default Vue.extend({
  name: 'ReflectionStatusTable',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    Searchbox,
    ShowPerPage,
    Pagination,
    DisplayOption,
    SelectPerPage,
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    statusTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 100,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataCommands: {
      type: Array as PropType<Type[]>,
      default: () => [],
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '#',
            align: 'start',
            sortable: false,
            width: '100px',
            value: 'revision',
          },
          {
            text: '詳細',
            align: 'start',
            sortable: true,
            value: 'detail',
          },
          {
            text: '登録日時',
            align: 'start',
            sortable: true,
            value: 'registered',
          },
          {
            text: '最終送信日時',
            align: 'start',
            sortable: true,
            value: 'sendAt',
          },
          {
            text: 'ステータス',
            align: 'start',
            sortable: true,
            value: 'status',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
    headerChild: {
      get(): DataTableHeader[] {
        const headerChild: DataTableHeader[] = [
          {
            text: '個人コード',
            align: 'start',
            sortable: false,
            value: 'pCodeId',
          },
          {
            text: 'ユーザーID',
            align: 'start',
            sortable: false,
            value: 'userId',

          },
          {
            text: 'ユーザー名',
            align: 'start',
            sortable: false,
            value: 'userName',
          },
        ];
        return headerChild;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  data: (): DataType => ({
    searchTargetItems: [
      { label: '内容', value: 'detail' },
    ],
    selectedItem: [],
    selectedDisplayOption: [
      'detail',
    ],
    displayOptionItems: [
      { label: '内容', value: 'detail' },
      { label: 'ステータス', value: 'status' },
      { label: 'Type', value: 'type' },
    ],
  }),
  watch: {

  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
      });
      return items;
    },
    converDateShort(t: any) {
      if (t !== undefined && t !== null && t !== '') {
        return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm:ss');
      } else {
        return '';
      }
    },
    itemRowBackground: (item: { status: number; }) => {
      return 'bg bg' + item.status ;
    },
  },
});
